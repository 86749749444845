import React from 'react'
import Main from '../layouts/main'

import styles from './404.module.scss'

const NotFoundPage = () => (
  <Main>
    <div className={styles.main}>
      <div className={styles.svgImage}>
        <svg className={styles.monsterIcon}/>
      </div>
      <h1>
        404
      </h1>
      <p>Oops! This page doesn&#39;t exist.</p>
    </div>
  </Main>
)

export default NotFoundPage
